<template>
  <div class="container">
    <div class="content" v-html="richTextContent"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      richTextContent: `<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9; text-align: center;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><span style="text-indent: 19.872px;">亿嘉欣</span>隐私权政策</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9; text-align: center;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">特别提示条款</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">感谢您选择<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>！</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">本政策仅适用于四川亿嘉欣家政服务有限公司及其关联公司（以下统称&ldquo;亿嘉欣&rdquo;或&ldquo;我们&rdquo;）提供的产品及服务，包括但不限于通过<span style="text-indent: 19.872px;">亿嘉欣</span>APP、小程序客户端或其他软硬件为您提供的产品及服务。如我们提供的产品、服务或开展的活动设置了单独的隐私政策或相应的用户服务协议当中存在特殊约定，则您使用上述产品、服务或参加相关活动时单独的隐私政策或特殊约定将优先适用。相应单独的隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">版本更新日期：2024年1月12日</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">版本生效日期：2024年1月20日</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">电子邮件：3471768870@qq.com</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">客服电话：<span style="font-family: 'Helvetica Neue'; font-size: 13px;">13154949714</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则。同时，我们承诺，我们将依据真实、准确、完整原则草拟、发布本政策，并按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请在使用我们的产品或服务前，仔细阅读并了解本《<span style="text-indent: 19.872px;">亿嘉欣</span>隐私权政策》，特别是粗体字和粗体字加下划线条款，与您的权益存在重大关系，请您特别予以关注。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">本政策将帮助您了解以下内容：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">一、我们如何收集和使用您的个人信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">二、我们如何使用Cookie和同类技术</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">三、我们如何委托处理、共享、转让、公开披露您的个人信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">四、我们如何保护您的个人信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">五、您的权利</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">六、我们如何处理未成年人的个人信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">七、您的个人信息如何在全球范围转移</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">八、本隐私权政策如何更新</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">九、与其他网站的链接</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">十、如何联系我们</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">一、我们如何收集和使用您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">如我们收集、使用的信息涉及您的个人敏感信息的，我们将用加粗字体加下划线突出显示以向您明确标识。同时，我们将采取加密及其他安全措施传输和存储您的个人敏感信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">如果您公开发布的信息中涉及敏感信息、他人信息，请谨慎选择及考虑。若您公开发布的信息中涉及未成年人个人信息的，您需要在发布前征得对应未成年人的监护人同意。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们将您的个人信息用于本政策未载明的其他用途或目的时，会事先征求您的同意。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（一）您需要向我们提供或允许我们收集和使用的必要信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">为了实现我们的产品、服务的核心功能（包含实现在线下单门店自提、在线下单外卖配送、在线购物所必须的功能及保障交易安全所必须的功能），我们可能会收集、保存和使用下列与您有关的信息。如果您不提供相关信息，我们将无法为您提供相应的产品或服务。这些功能和信息内容如下：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.帮助您完成账号注册、登录及相关验证流程 </span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&ldquo;<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>平台&rdquo;指包括<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>提供产品或服务的APP、小程序和其他客户端，我们基于<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">平台账号为您提供服务。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">当您注册成为<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>平台会员或登录<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>平台客户端时，您至少需要向我们提供<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">手机号码和<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您收到的验证码</u></span>，我们会通过短信息方式帮您完成登录、注册的验证流程。如果您仅需使用基本服务如浏览、搜索，您不需要注册成为会员及提供上述信息。如果您通过第三方渠道注册、登录<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>客户端的，您需要授权我们获取<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">您在第三方的身份信息如电话号码、昵称、头像、OPEN ID及页面提示的其他信息</span>。您可以通过<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span>APP、小程序中&ldquo;我的&rdquo;页面点击账号头像进入个人资料页，继续完善和修改您的&ldquo;个人资料&rdquo;信息，包括头像、用户名、性别、我的幸运日、关联手机、收货地址。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您完善的信息将有助于我们为您提供定制化权益（如每年可领取一次幸运日权益）、个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用我们提供的核心功能。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">请您注意，为了帮助您准确取得您所购买的商品，您的用户名将会被展示在订单小票上，请您审慎命名、及时更名。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.帮助您浏览和搜索商品/服务信息，搜索附近门店</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">当您浏览商品/服务信息，搜索附近门店时，我们需要收集您的设备信息（其中可能包含软硬件特征信息如设备唯一标识符、设备应用安装列表、WiFi信息、Android ID）来为您以最优方式展示附近门店、门店内商品/服务信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">当您使用商品/服务信息搜索、&ldquo;收藏口味&rdquo;、&ldquo;口味定制&rdquo;、购物车功能时，我们需要收集您的<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">浏览记录</u>、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">收藏记录</u>、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">定制记录</u>、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">加购信息</u>、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">订单信息</u>。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们会使用上述信息，结合门店运营情况、平台用户的关注热点和消费趋势，分析您的偏好，并依据您的偏好在</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="font-weight: bolder; text-indent: 1.5em;">平台或第三方平台向您推荐个性化的商品/服务信息。如果您不想看到个性化推荐的信息，您可以通过以下方式实现：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（1）在&ldquo;我的&mdash;头像&mdash;个人资料&rdquo;页关闭个性化推荐功能，您仍会看到推荐内容，但推荐内容将降低相关性；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（2）回复&ldquo;TD&rdquo;退订短信息推送服务；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（3）在&ldquo;搜索框&mdash;搜索历史&rdquo;删除搜索记录。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">已经向您发送的优惠券不会因您关闭或退订服务而被收回，您可以在&ldquo;我的&rdquo;中查看<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">&ldquo;钱包&rdquo;、&ldquo;优惠券&rdquo;</u>相关项目确认您的权益。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如您在未登录<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">平台账号的前提下浏览商品/服务信息的，您将不会看到个性化推荐的商品/服务信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3.帮助您完成下单及订单管理，为您提供客服及售后服务</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">当您将商品添加至购物车，要对购物车中的商品进行结算时，我们会为您生成您购买相关商品的订单。您至少需要提供给我们您的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">收货人姓名、手机号码、收货地址（所在区域、详细地址、门牌号</span>，您可以为地址增加&ldquo;公司&rdquo;、&ldquo;家&rdquo;、&ldquo;学校&rdquo;标签，便于您对地址进行管理<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">）</span>，同时该订单会载明<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">订单类型、订单号、下单时间、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您购买的商品信息、您应支付的款项（包括优惠减免信息）、您支付款项的方式以及下单时间</u>、取餐时间（外卖订单包含送达时间）及其他相关信息</span>，上述所有信息构成您的&ldquo;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">订单信息</span>&rdquo;，我们收集、使用（包括与为您提供上述服务的第三方）这些信息是为了帮助您顺利完成交易、保障您的交易安全。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">同时，您的订单信息，将成为我们为您解决争议的依据之一，以及我们联系您的方式，我们将使用上述信息，为您提供客服与售后服务。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4.帮助您开具发票</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您需要开具发票的，至少需要向我们提供<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">开票方式、发票抬头、纳税人识别号（发票抬头为单位）</span>、接收发票的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">邮箱地址</span>，以便我们完成发票开具。同时我们将保存您的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">开票记录</u></span>，以便您查询或为您提供售后服务，您可在&ldquo;我的&mdash;发票管理&mdash;发票记录&rdquo;中找到相关信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为了便于您开具发票，您可以在&ldquo;我的&mdash;发票管理&mdash;发票抬头&rdquo;中添加、管理您的发票抬头。您至少要提供给我们<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">发票抬头、纳税人识别号</span>和接收发票的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">邮箱地址</span>，您也可以填写您的注册地址、注册电话、<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">开户银行以及银行账号信息</u></span>，以便我们能开具更准确更完整的发票，但不填写这些信息不影响您发票的开具。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">5.帮助您完成支付</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以选择使用第三方支付平台（其中包含支付宝、微信、ApplePay、京东支付、翼支付、银联云闪付等银行/金融机构支付工具、数字货币，以下统称为&ldquo;支付平台&rdquo;）及其提供的不同付款方式来完成订单支付。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">您支付时，需要向我们提供或授权我们从您选择的支付平台获得您的同支付相关的信息，其中可能包含<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付账号信息</u>，以使我们及时获悉并确认您的支付进度和状态，以帮助您完成支付。如果您选择其他金融机构为您提供的支付服务，我们可能获取您的支付信息包括<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">银行卡号、安全码</u>、有效期并共享给支付机构以帮助您完成支付，如果您按照页面提示输入、勾选、点击授权及其他方式提供了上述信息，视为您已同意并授权。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">6.保障交易安全所必须的功能</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的信息来判断您的账号风险，收集的信息中可能包含<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">设备信息（其中可能包含IP地址、</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">IDFA、</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">设备唯一标识符如MEID/UDID/OPENUDID、MAC地址、IMEI、OAID、ICCID(SIM卡序列号)、WiFi信息如BSSID/SSID、网络状况、设备型号及状态、GUID、网络环境、操作系统类型及软件版本）、<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">历史上网记录</u>、日志信息</span>，我们会用上述信息排查可能存在的系统风险、安全风险和信息异常。如果您不提供上述信息，我们无法对您的账号安全及账号权益进行保护。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">7.推荐、展示附近门店菜单</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">在您使用<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">在线下单门店自提、在线下单外卖配送服务购买商品</span>时，您需要向我们提供您的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">地理位置信息</u></span>，以便我们能向您推荐、展示您附近门店的菜单（商品清单）、优惠活动相关的营销信息，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">其中<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">精确定位</u>信息可以帮助您知晓门店同您的距离</span>。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">在您通过系统授权开启移动设备的定位功能时，我们可能会使用有关技术获取您的位置信息，这些技术可能包括IP地址、GPS以及能够提供相关信息的WLAN（如Wi-Fi） 接入点、蓝牙和基站，以及您或其他用户提供的包含您所处<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">地理位置的实时信息</u>（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹），例如您提供的账号信息中包含的您所在地区信息。</span>您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们可能会对收集的上述信息进行匿名化的研究、统计分析和预测，用于改善门店的布局，为商业决策提供支撑，此类处理后数据的使用我们将不再另行向您通知并征得您的同意。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（二）您可自主选择向我们提供或允许我们收集和使用的信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">为了实现我们的产品、服务的拓展功能（包含语音聊天、扫一扫、换头像功能），您可能需要授予我们一些权限才能实现上述这些功能。如果您不提供相关信息，您依然可以使用我们的核心功能，但您可能无法使用拓展功能。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">这些功能包括：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.基于摄像头（相机）的扩展功能</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">当您使用&ldquo;扫一扫&rdquo;功能加入<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">企业帐号、内部店、拍照上传个人头像、不定期营销活动中的&ldquo;开启AR&rdquo;、上传视频功能时，需要开启相机权限。</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请您知晓，即使您已经同意开启相机权限，我们也仅会在您主动点击客户端内上述功能时通过相机获取相关信息</span><span style="text-indent: 1.5em;">。若您不使用以上功能时，可以不允许此权限。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">获取加入您的照片/相册权限</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以在开启相册权限后使用该功能上传您的照片/图片，以实现更换头像、生成美图分享海报功能。若您不使用该功能，可以不允许此权限。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">获取麦克风及音频权限</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以选择开启系统的麦克风及音频权限，该权限可以在您使用在线客服功能时，通过发送语音进行沟通。我们会收集您在使用智能语音技术过程中录入的语音信息及录音内容用于机器识别、在线交互并满足您的查询活动或咨询信息类的需求（例如您在使用&ldquo;在线客服&rdquo;功能时，我们会根据您的需求进行信息的搜索、展示）。此项功能您可以在系统权限中关闭，一旦关闭您将无法实现在线语音交互功能，但不会影响您继续浏览我们的APP页面。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击麦克风图标时通过麦克风获取语音信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4.<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">获取向您发送通知权限</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为改善我们的产品和/或服务、向您提供个性化的信息搜索及交易服务，我们会依据您已经授权的信息向您发送商业营销信息（方式包含短信息、营销电话、手机推送消息）。如果您不想接受我们给您发送的商业营销信息，您可以通过：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;&nbsp;&nbsp; 手机关闭相关权限，即可在&ldquo;手机设置-隐私管理-权限管理&rdquo;中更改状态(各厂商机型设置路径可能存在不一致，用户可参考厂商设置说明)来关闭来自<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">APP的推送消息；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;&nbsp;&nbsp; 按短信提示，编辑短信回复&ldquo;TD&rdquo;来退订此类短信；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;&nbsp;&nbsp; 通过客服电话告知我们拒绝营销电话。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您理解并同意，上述拓展功能可能需要您在您的设备中开启您的位置信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（地理位置）、摄像头（相机）、相册（图片库），以实现这些权限所涉及信息的收集和使用。您可在您的设备手机设置-隐私管理-权限管理（各厂商机型设置路径可能存在不一致，用户可参考厂商设置说明）中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">5.您参与调查、互动或其他活动提供或授权的信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">如果您参与了一项调查、互动或我们主办、承办的活动，您将授权我们收集您通过页面主动填写、勾选或确认同意的授权文件中的信息，我们只会将相关信息应用于对应的调查、互动或活动。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">例如：您参与&ldquo;</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="font-weight: bolder; text-indent: 1.5em;">小调研&rdquo;的，我们会收集您填写的问卷信息，用于完善我们的产品和服务。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">例如：您通过&ldquo;我的&mdash;招商加盟&rdquo;在线申请成为</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="font-weight: bolder; text-indent: 1.5em;">合作伙伴的，我们会收集您填写在页面的姓名、手机号、</span><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">验证码</u><span style="font-weight: bolder; text-indent: 1.5em;">、申请开店省份、申请开店城市、申请开店区县信息，以判断您是否可以成为我们合作伙伴及联系您使用。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">例如：您通过</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">APP&ldquo;我的&mdash;&mdash;加入我们&rdquo;或</span><span style="text-indent: 19.872px; font-weight: bold;">亿嘉欣</span><span style="text-indent: 1.5em; font-weight: bolder;">招聘微信公众号应聘搜</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="font-weight: bolder; text-indent: 1.5em;">的工作岗位，我们会依据您的操作收集您的简历信息；当您应聘门店的工作岗位时，我们会依据您的授权收集您的地理位置信息以查看您位置附近的门店。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（三）征得授权同意的例外</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.与个人信息控制者履行法律法规规定的义务相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.与国家安全、国防安全直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">6.所涉及的个人信息是个人信息主体自行向社会公众公开的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">7.根据个人信息主体要求签订和履行合同所必需的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">8.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">9.维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">请知悉，根据相关法律规定，若我们对个人信息采取技术措施和其他必要措施处理，使得数据接收方法无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化的研究、统计分析和预测，用于改善<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">客户端的内容和布局，为商业决策提供产品和服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如我们停止运营，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">二、我们如何使用Cookie和同类技术</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（一）Cookie 的使用</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为 Cookie 的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息（例如一键登录）的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全。我们不会将 Cookie 用于本隐私权政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机或移动设备上保存的所有 Cookie，您有权接受或拒绝 Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝 Cookie；另外，您也可以清除软件内保存的所有 Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（二）网络Beacon和同类技术的使用</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">除Cookie外，我们网页上常会包含一些电子图像(称为"单像素"GIF文件或"网络Beacon")或其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些Cookie。我们使用网络Beacon的方式有：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.我们通过在我们网站上使用网络Beacon，计算用户访问数量，并通过访问Cookie辨认注册用户；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.我们通过得到的Cookie信息，可以在我们网站提供个性化服务。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（三）SDK的使用</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们会对与我们共享信息的第三方获取有关信息的应用程序编程接口（API）、软件工具开发包（SDK）进行严格的安全检测，并要求其对您的信息采取保护措施，严格遵守相关法律法规与监管要求。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">您可以通过以下《SDK目录》了解我们SDK收集信息的相关内容。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">以下是我们接入的SDK，信息主要来自SDK提供方向我们披露的内容，结合我们通过技术手段检测到的情况。请您理解，我们无法随时监测SDK提供者收集个人信息的真实情况，如您希望进一步了解这些SDK如何收集和使用个人信息，您可以查询SDK方提供的隐私政策。请注意SDK提供方的隐私政策将会不断更新，最终版本以相应提供方的官网公示为准。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">华为推送/HMS-Core</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：用于消息推送</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：应用信息、设备信息（设备的硬件信息、系统基本信息和系统设置）&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：华为软件技术有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177" target="_blank" rel="noopener noreferrer">https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">VIVO消息推送</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：推送信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：匿名用户ID（OAID）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：维沃移动通信有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://www.vivo.com.cn/about-vivo/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.vivo.com.cn/about-vivo/privacy-policy</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">ColorOS</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：消息推送</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：匿名用户ID（OAID）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：OPPO广东移动通信有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://privacy.oppo.com/cn/policy/" target="_blank" rel="noopener noreferrer">https://privacy.oppo.com/cn/policy/</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">小米推送</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：推送信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备标识符（（如GUID））、网络状态、网络制式</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：北京小米移动软件有限公司及其关联公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://dev.mi.com/console/doc/detail?pId=1822" target="_blank" rel="noopener noreferrer">https://dev.mi.com/console/doc/detail?pId=1822</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">地图SDK（Android、iOS、HarmonyOS）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：用于辅助位置展示场景，如地图图面门店展示</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：位置信息（经纬度、精确位置、粗略位置）、设备信息【如IP 地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); color: #767676;">SSID、BSSID、</span>基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速度、压力、方向、地磁）、设备信号强度信息、外部存储目录（如SD Card）】、设备标识信息（IMEI、IDFA、IDFV、Android ID、MEID、MAC地址、OAID、IMSI、ICCID、硬件序列号）、当前应用信息（应用名、应用版本号）、设备参数及系统信息（系统属性、设备型号、操作系统、运营商信息）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：高德软件有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://lbs.amap.com/pages/privacy/" target="_blank" rel="noopener noreferrer">https://lbs.amap.com/pages/privacy/</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">定位SDK（Android、iOS）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：用于推荐附近门店</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：位置信息（经纬度、精确位置、粗略位置）、设备信息【如IP 地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、SSID、BSSID、基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速度、压力）、设备信号强度信息、外部存储目录（如SD Card）】、设备标识信息（IMEI、IDFA、IDFV、Android ID、MEID、MAC地址、OAID、IMSI、ICCID、硬件序列号）、当前应用信息（应用名、应用版本号）、设备参数及系统信息（系统属性、设备型号、操作系统、运营商信息）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：高德软件有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://lbs.amap.com/pages/privacy/" target="_blank" rel="noopener noreferrer">https://lbs.amap.com/pages/privacy/</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">闪验（联通/移动/电信运营商校验）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：一键登录</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备信息（IMEI/OAID、IMSI、ICCID、MAC、IP、手机型号、手机厂商、系统类型、系统版本及其他设备信息）、网络环境信息、网关取号报错日志</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：上海璟梦信息科技有限公司及关联方</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://doc.chuanglan.com/document/T98GE1KYCZDMJDHN" target="_blank" rel="noopener noreferrer">https://doc.chuanglan.com/document/T98GE1KYCZDMJDHN</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">一键登录(中国移动认证）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：识别用户的手机号码快速登录&nbsp;&nbsp;&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：网络类型、网络地址、运营商类型、本机号码</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中移互联网有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://wap.cmpassport.com/resources/html/contract.html" target="_blank" rel="noopener noreferrer">https://wap.cmpassport.com/resources/html/contract.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">一键登录(中国联通认证)</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：识别用户的手机号码快速登录&nbsp;&nbsp;&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：网络类型、网络地址、运营商类型、本机号码</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中国联合网络通信有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://ms.zzx9.cn/html/oauth/protocol2.html" target="_blank" rel="noopener noreferrer">https://ms.zzx9.cn/html/oauth/protocol2.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">一键登录(中国电信认证)</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：识别用户的手机号码快速登录&nbsp;&nbsp;&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：网络类型、网络地址、运营商类型、本机号码</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：天翼数字生活科技有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #69b1ff;" href="https://e.189.cn/sdk/agreement/detail.do?hidetop=true" target="_blank" rel="noopener noreferrer">https://e.189.cn/sdk/agreement/detail.do?hidetop=true</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">京东钱包</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：完成支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：网银在线（北京）科技有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="http://storage.360buyimg.com/protocols/format/ef718cc86a2f51fcacbb7e2553247939.html" target="_blank" rel="noopener noreferrer">http://storage.360buyimg.com/protocols/format/ef718cc86a2f51fcacbb7e2553247939.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">银联云闪付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：完成支付（包含银联调起ApplePay）、安全和防欺诈</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span>、设备识别信息（设备型号、设备名称、序列号、设备MAC地址、操作系统类型）、位置信息、手机号码</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中国银联股份有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://base.95516.com/s/wl/WebAPP/helpAgree/page/agreement/regPrivacy.html" target="_blank" rel="noopener noreferrer">https://base.95516.com/s/wl/WebAPP/helpAgree/page/agreement/regPrivacy.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">翼支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：完成支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中国电信</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://e.189.cn/_agreements.html" target="_blank" rel="noopener noreferrer">https://e.189.cn/_agreements.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">支付宝</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：完成支付、保障支付安全</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u>、</span>IP信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：支付宝（中国）网络技术有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://render.alipay.com/p/c/k2cx0tg8" target="_blank" rel="noopener noreferrer">https://render.alipay.com/p/c/k2cx0tg8</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">微信（登录、支付、分享）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：使用微信账号登录、使用微信完成支付或分享</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：身份信息及<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span>，如微信头像、昵称</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：财付通支付科技有限公司、腾讯科技（深圳）有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://pay.weixin.qq.com/index.php/core/home/pay_pact_v4" target="_blank" rel="noopener noreferrer">https://pay.weixin.qq.com/index.php/core/home/pay_pact_v4</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2" target="_blank" rel="noopener noreferrer">https://pay.weixin.qq.com/index.php/public/apply_sign/protocol_v2</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl" target="_blank" rel="noopener noreferrer">https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">招商银行</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：招商银行支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：招商银行股份有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="http://www.cmbchina.com/personal/netbank/NetbankInfo.aspx?guid=ca085e03-b3f2-4ac8-8a35-3560e8276cb2" target="_blank" rel="noopener noreferrer">http://www.cmbchina.com/personal/netbank/NetbankInfo.aspx?guid=ca085e03-b3f2-4ac8-8a35-3560e8276cb2</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">建设银行</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：建设银行支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中国建设银行股份有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="http://www.ccb.com/cn/mycom/register_xy_secret.html" target="_blank" rel="noopener noreferrer">http://www.ccb.com/cn/mycom/register_xy_secret.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">民生银行</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：民生银行支付</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">支付信息</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：中国民生银行股份有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="http://www.cmbc.com.cn/m/privacyPolicyContextEnt.html" target="_blank" rel="noopener noreferrer">http://www.cmbc.com.cn/m/privacyPolicyContextEnt.html</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">数字联盟（数据分析）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：验证服务、安全风控</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备信息（如<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">IDFA</span>、设备制造商、设备型号、设备系统版本、应用版本、MAC地址、设备网络状况信息及唯一设备标识符、设备应用安装清单）、传感器(光传感器、磁场传感器、重力传感器、压力传感器、方向传感器、旋转矢量传感器、陀螺仪传感器、加速度传感器)</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：北京数字联盟网络科技有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://www.shuzilm.cn/privacy" target="_blank" rel="noopener noreferrer">https://www.shuzilm.cn/privacy</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">同盾</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：验证服务、安全风控</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备信息（包括设备标识信息、设备类型、设备型号、系统类型、登录IP地址、设备App列表信息、Android ID等）、日志信息、传感器（光传感器、重力传感器、磁场传感器、加速度传感器、陀螺仪传感器、方向传感器）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：同盾科技有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://www.tongdun.cn/other/policy" target="_blank" rel="noopener noreferrer">https://www.tongdun.cn/other/policy</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">腾讯bugly&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：云平台、企业协作、辅助运营&nbsp;&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备唯一标识码、网络状态、设备类型、设备制造商、设备版本、系统版本、Android ID</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构：腾讯科技（深圳）有限公司</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf" target="_blank" rel="noopener noreferrer">https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">Facebook</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：用于数据分析、分享、第三方登录</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备序号</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构: facebook Inc</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://zh-cn.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">https://zh-cn.facebook.com/privacy/explanation</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">chromium</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">功能：用于辅助开发</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收集信息：设备版本、IMSI、设备应用安装列表、网络设备制造商、MAC地址、网络运营商、Wifi信息如BSSID/SSID/ICCID、位置信息、手机制造商、IMEI、唯一设备标识符（如MEID/UDID/OPENUDID）、经度、纬度</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">所属机构: Google Inc</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">隐私政策：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="https://policies.google.cn/" target="_blank" rel="noopener noreferrer">https://policies.google.cn/</a></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">此外我们还使用了一些功能性的SDK，详情如下：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们使用Apache用于辅助开发，使用qihoo/util用于APP360加固，使用MBProgressHUB用于HUD弹窗loading动画视图UI控件，使用TalkingData用于H5页面调起ApplePay，使用WebImage作为图片轮播UI控件，使用MJRefresh用于控制页面下拉刷新和上拉加载，使用AFNetworking用于网络请求框架，使用paySDK用于在H5页面调取各支付平台，使用第三方图片选择工具库作为在线客服功能图片选择控件，使用CarTrawler作为RN工具，使用SVGAPlayer用于呈现动效效果，使用神策用于统计数据。以上SDK均不收集您的个人信息，仅为实现系统功能使用。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">三、我们如何委托处理、共享、转让、公开披露您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（一）委托处理</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们的功能中某些具体的模块或功能由合作方提供，我们将按照具体情况委托相关合作方处理您的信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1、 供应商、服务提供商等合作方：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">如当您选择在线下单外卖配送方式购买商品时，为向您提供配送服务，我们必须委托配送服务合作方处理您的个人信息，这些信息包括但不限于您的订单信息、电话号码信息</span>。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">如当我们使用手机短信方式向您发送信息时，为了向您提供短信服务，我们必须委托短信服务合作方处理您的个人信息，这些信息包括但不限于您的电话号码信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2、 广告、分析类合作方：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们将在采用行业通用的安全技术前提下，与提供广告、数据分析服务的合作方开展合作，委托这类合作方处理与广告投放、覆盖面和有效性相关的信息，或是进行数据分析。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（二）共享</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们不会与我们以外的公司、组织和个人共享您的个人信息，但以下情况除外：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.与关联公司/<span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">合作伙伴间共享：为便于我们基于</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">平台账号和</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">品牌门店向您提供服务，推荐您可能感兴趣的信息，识别会员账号异常状态，保护</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">关联公司或其他用户或公众人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司/</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em;">合作伙伴共享；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请您特别注意，您可能在</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">平台浏览到</span><span style="text-indent: 19.872px; font-weight: bold;">亿嘉欣</span><span style="text-indent: 1.5em; font-weight: bolder;">客户端的商品及服务信息。为了确保您能在</span><span style="font-weight: bold; text-indent: 19.872px;">亿嘉欣</span><span style="text-indent: 1.5em; font-weight: bolder;">平台的全部客户端正常使用平台账号、浏览信息、完成交易并使用售后服务，我们将会同平台内各客户端共享您的平台帐户信息、</span><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您在平台内各客户端的操作记录</u><span style="text-indent: 1.5em; font-weight: bolder;">及各客户端收集的您的信息；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4.当您的相关个人信息已经自行向社会公众公开，或通过合法、公开的渠道（如新闻报道、政府信息公开渠道）被对外披露的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.法律、法规规定的其他情形。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（三）转让</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.事先获取您的明确同意。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.在我们发生合并、收购、资产出售、重组或破产清算情形，或其他涉及合并、收购、资产出售、重组或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。如我们破产且无承接方的，我们将对数据做删除处理。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（四）公开披露</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们仅会在以下情况下，公开披露您的个人信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.获得您明确同意后；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.如果我们确定您出现违反法律法规或严重违反我们相关协议规则的情况，或为保护我们及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括您的相关违规行为以及我们已对您采取的措施；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3.为了保护您或他人的生命、财产等重大合法权益，但又难以获得您的事先同意的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（五）委托处理、共享、转让、公开披露个人信息时事先征得授权同意的例外</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">以下情形中，委托处理、共享、转让、公开披露您的个人信息无需事先征得您的授权同意</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.与个人信息控制者履行法律法规规定的义务相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.与国家安全、国防安全直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">6.个人信息主体自行向社会公众公开的个人信息；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请知悉，根据现行有效的法律规定，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的委托处理、共享、转让、公开披露无需另行向您通知并征得您的同意。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">四、我们如何保护您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（一）我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经完成公安部信息系统等级保护第三级备案，并获得CCRC移动互联网应用程序(App)安全认证，此外我们取得了CCRC数据安全管理认证、ISO/IEC 27001信息安全管理体系认证、ISO/IEC 27701隐私信息管理体系认证和ISO/IEC</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">38505 数据治理安全认证。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（三）我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非延长保留期征得您的同意或法律有强制的存留要求。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（四）互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送您的信息。如您发现自己的个人信息尤其是您的帐号或密码发生泄露，请您立即联络亿嘉欣客服，以便我们根据您的申请采取相应措施。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">请协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、簒改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、信函、电话、推送通知或其他方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（六）<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们存储您的个人信息的期限只会在为实现您达成本政策所描述的使用目的所必须的期限，法律法规另有规定或者您另行授权同意的除外</span>，如《中华人民电子商务法》规定商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2、保证我们为您提供服务的安全和质量；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3、您是否同意更长的留存期间；</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4、是否存在关于保留期限的其他特别约定或法律法规规定。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">超出存储期限后，您的个人信息将被删除或匿名化处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">五、您的权利</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">按照目前法律、法规、标准，以及国家、地区的通行做法，我们保障您对自己的个人信息行使访问、更正、补充、删除以及改变授权范围的权利，具体如下：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（一）访问您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">除法律法规规定的例外情况，无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.账号信息：您可以进入亿嘉欣APP、小程序，在&ldquo;我的&mdash;点击头像进入个人资料页&rdquo;访问、查看您的账号信息，包括：头像、用户名、性别、幸运日、关联手机信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.收货信息：您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&rdquo;访问并查看您的收货地址信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.订单信息：您可以在&ldquo;我的&mdash;我的订单&rdquo;中访问并查看您的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">已完成及已取消的订单记录</u></span>。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">除上述列明的信息外，您的部分个人信息我们无法为您提供访问服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问，但您可联系我们进行删除或做匿名化处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（二）更正您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以通过以下方式更正您的个人信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.账号信息：您可以进入亿嘉欣APP、小程序，在&ldquo;我的&mdash;点击头像进入个人资料页&rdquo;更正您的账号信息，包括：头像、用户名、性别、幸运日、关联手机。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.收货信息：您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&rdquo;更正您的收货地址信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">当您需要更新您的个人信息时，或发现我们处理您的个人信息有错误时，您有权作出更正或更新。您可以自行在我们系统内进行更正，或通过反馈与报错方式将您的更正申请提交给我们。在您进行更新或更正前，我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">除上述列明的信息外，您的部分个人信息我们无法为您提供更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法更正，但您可联系我们进行删除或做匿名化处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（三）删除您的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">收货信息：您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&rdquo;删除您的收货地址信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">订单信息：您可以在&ldquo;我的&mdash;我的订单&rdquo;中删除您的<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">已完成及已取消的订单记录。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">删除订单信息，可能导致售后服务无法提供，请您在审慎决策。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">搜索记录：您可以在&ldquo;搜索框&mdash;搜索历史&rdquo;中删除您的<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">搜索记录</u>。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">此外，在以下情形中，您也可以联系我们提出删除个人信息的请求：</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.如果我们处理个人信息的行为违反法律法规；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.如果我们收集、使用您的个人信息，却未征得您的同意；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.如果我们处理个人信息的行为违反了与您的约定；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4.如果您不再使用我们的产品或服务，或您注销了账号；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.如果我们不再为您提供产品或服务。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除。除非法律法规另有规定，或这些主体已经获得您独立/单独的授权，需要您另行向该主体提出请求。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">您确认知悉：当您删除相关信息后，由于法律和安全技术原因，我们可能无法立即从备份系统中删除相应的信息，我们将安全的存储您的个人信息并对其做进一步处理隔离，直到备份系统可以对此类信息进行删除或做匿名化处理。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">联系我们的方式：</span>电子邮件：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="mailto:privacy@lkcoffee.com" target="_blank" rel="noopener noreferrer">privacy@lkcoffee.com</a>&nbsp;&nbsp;&nbsp;客服电话：4000-100-100</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">联系地址：厦门市思明区台北路1-3号海西金谷广场T3号楼</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（四）改变您授权同意的范围</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">每个业务功能需要一些基本的个人信息才能得以完成（见本政策）。除此之外：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以通过修改个人资料、关闭部分功能、注销账号方式撤回部分授权，具体方法如下：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&mdash;账号管理&rdquo;绑定或解除绑定您的微信；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&mdash;通用设置&rdquo;开启或关闭推送通知、个性化推荐功能；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&mdash;通用设置&mdash;隐私设置&rdquo;开启或关闭地理位置定位、使用相机、访问相册、使用麦克风的授权。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（五）注销账号</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您随时可注销注册的亿嘉欣账号，您可以通过&ldquo;我的&mdash;点击头像进入个人资料页&mdash;账号管理&mdash;注销账号&rdquo;自行操作或联系客服为您注销账号，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请您仔细阅读注销账号页面的说明以了解账号注销条件及注销效果。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您有权按照上述说明申请退款，款项无法原路退回的，我们需要您提供<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">收款账户信息</u></span>以向您支付退款。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">在注销账号之后，我们将停止为您提供产品或服务，并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。如，依据《中华人民共和国电子商务法》的规定，要求商品和服务信息、交易信息保存的时间自交易完成之日起保存不少于三年。在您的个人信息超出保留期间后，我们会根据法律的要求删除您的个人信息，或使其匿名化处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（六）获取个人信息副本</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您有权获取您的个人信息副本，在符合法律法规及监管要求的前提下，您可以通过&ldquo;我的-点击头像&rdquo;查询或截图获取。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（七）约束信息系统自动决策</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">在某些业务功能中，我们可能仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害亿嘉欣商业秘密或其他用户合法权益、社会公共利益的前提下提供适当的救济方法。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您可以通过本政策第十条列明的方式联系我们。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（八）回应您的上述请求</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为保障安全，我们可能会先要求您提交相关信息验证自己的身份，然后再处理您的请求。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们将在15个日内做出答复。如您不满意，还可以通过如下途径进行咨询和投诉：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">电子邮件：<a style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); background: transparent; outline: none; text-decoration-line: none; color: #333333;" href="mailto:privacy@lkcoffee.com" target="_blank" rel="noopener noreferrer">privacy@lkcoffee.com</a>&nbsp;&nbsp;&nbsp;客服电话：4000-100-100</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">联系地址：厦门市思明区台北路1-3号海西金谷广场T3号楼</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于与您的身份不直接关联的信息、无端重复的信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.与个人信息控制者履行法律法规规定的义务相关的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.与国家安全、国防安全直接相关的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">7.响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">8.涉及商业秘密的。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">六、我们如何处理未成年人的个人信息</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们将不满18周岁的自然人视为未成年人。我们的产品和服务主要面向成年人。如果没有父母或监护人的同意，未成年人不应创建自己的亿嘉欣账号。已经创建账号的，我们推定您是具有相应的民事行为能力的成年人。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如果我们发现自己在未经事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果您作为父母或监护人发现我们在未经事先可证实的情况下收集了被监护的未成年人的个人信息，您可以通过本政策标明的联系方式告知我们删除相关信息。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">七、您的个人信息如何在全球范围转移</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内，以下情形除外：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.法律法规有明确规定；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.获得您的明确授权；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.您通过互联网进行跨境交易、使用跨境支付产品或服务的个人主动行为。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如我们提供的产品或服务涉及跨境，我们需要向境外传输您的个人信息的，我们将特别提示您并取得您授权，同时我们会确保依据本隐私权政策及相关法律法规对您的个人信息提供足够的保护。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">八、本隐私权政策如何更新</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们的隐私权政策可能变更。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">未经您明确同意，我们不会削减您按照本隐私权政策所应享有的权利。我们会在专门页面（如在&ldquo;我的&mdash;帮助与回馈&mdash;隐私权政策&rdquo;）上展示最新版的隐私权政策。我们鼓励您经常查看本政策，以便了解任何修订的内容。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">对于重大变更，我们会提供更为显著的通知（包括我们会通过短信息、电子邮件、客户端特别提示或弹窗授权或其它方式提示）</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">本政策所指的重大变更包括：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.我们的服务模式发生重大变化。其中可能包括处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.我们在控制权方面发生重大变化。如所有权结构、组织架构、因并购重组、破产之类的事件引起的所有者变更或其他情况；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3.个人信息共享、转让或公开披露的主要对象发生变化；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.个人信息安全影响评估报告表明存在高风险时。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们会将本政策的历史版本存档，您可以在本页面最下端查看历史版本，也可以联系我们的客服获得历史版本。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9; text-align: center;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">九、与其他网站的链接</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">请注意，我的客户端可能包含了可连接至其他网站的链接。这些网站或APP并不一定遵循本政策的规定。请您查阅并了解这些网站或APP各自对于隐私保护的相关规定。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">十、如何联系我们</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1.我们设立了专门的机构，负责处理个人信息保护相关事务，并指定了个人信息保护负责人，如对本政策内容有任何疑问、意见或建议，或发现个人信息可能被泄露，您可以通过如下方式向我们投诉举报：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">个人信息保护负责人电子邮件：<span style="font-family: 'Helvetica Neue'; font-size: 13px;">3471768870@qq.com</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">客服电话：<span style="font-family: 'Helvetica Neue'; font-size: 13px;">13154949714</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="text-indent: 1.5em;">2.一般情况下，我们将在15日内回复，</span><span style="text-indent: 1.5em; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">请您注意，我们可能不回复与本政策或您的个人信息无关的信息</span><span style="text-indent: 1.5em;">。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或向有管辖权的法院提起诉讼。</span>您查阅并了解这些网站或APP各自对于隐私保护的相关规定。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9; text-align: center;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">&nbsp;</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">更新日期：2023年12月23日&nbsp;&nbsp;&nbsp;生效日期：2023年12月31日</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">亿嘉欣是由四川亿嘉欣家政服务有限公司<span style="text-indent: 1.5em;">及其关联公司（以下统称为&ldquo;</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">我们</span><span style="text-indent: 1.5em;">&rdquo;）运营的咨询服务品牌。我们合法拥有并运营着亿嘉欣官方网站、客户端应用程序、小程序、公众号、H5页面等（以下统称为&ldquo;</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">亿嘉欣平台</span><span style="text-indent: 1.5em;">&rdquo;），致力于通过亿嘉欣平台向用户（以下亦称&ldquo;</span><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">您</span><span style="text-indent: 1.5em;">&rdquo;）提供优质的产品及相关服务。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">当您通过亿嘉欣平台注册并使用我们提供的产品及/或服务前，您需仔细阅读本《亿嘉欣用户服务协议》（下称&ldquo;本协议&rdquo;）的全部条款，特别是关于限制或免除责任的条款、违规或违约行为的认定和处理、争议解决条款等内容，我们已通过字体加粗及/或加下划线的显著形式提示您注意。如您在注册账号的过程中点击同意本协议，即视为您已阅读、理解、接受本协议的全部条款，本协议将对您具有法律约束力。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您理解并同意，我们通过亿嘉欣平台已发布或后续可能不时发布或更新的所有协议及规则，包括但不限于《亿嘉欣隐私权政策》</u></span>（查看路径：App/小程序&mdash;我的&mdash;点击头像进入个人资料页&mdash;协议与说明&mdash;隐私权政策）<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">、具体或专项服务的服务条款及规则等均属于本协议不可分割的组成部分，与本协议具有同等的法律效力。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">我们可能不时根据我们提供的产品及/或服务的实际更新和变化情况，及/或适用法律法规的规定及国家政策的调整，对本协议内容进行修改更新。我们会公布修订后的协议内容，并通过适当的方式（如客户端特别提示、弹窗提示或其它方式提示）通知您。修订后的协议经公示期后生效并代替原来的协议。如您继续使用我们提供的产品及/或服务，则视为您同意本协议的修订版本。如您不同意相关变更事项，您可于本协议修订版生效日前联系我们反馈意见。如您届时不同意该等变更事项的，您可以选择即刻停止使用我们的产品和服务。&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">如果您不满十八（18）周岁，您应与您的监护人共同阅读本协议，确保您及您的监护人理解并同意本协议的内容，并在进行账号注册、下单、支付等行为时取得您的监护人的同意。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.&nbsp;用户账号的注册、管理、使用及注销</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.1&nbsp;账号的注册</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;为了更充分地使用我们的产品及服务，您需通过亿嘉欣平台注册用户账号（以下亦称&ldquo;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">亿嘉欣账号</span>&rdquo;）。注册完成后，该账号是您以亿嘉欣平台注册用户身份登录、使用我们的产品及服务的凭证。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您注册、使用亿嘉欣账号，应确保您具备与法律规定相适应的民事行为能力。我们原则上仅建议18周岁以上的用户注册、使用账号。如果您是18周岁以下的未成年人或您存在其他限制民事行为能力的情形，您应仅在监护人同意下注册、使用账号。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">注册账号的过程中，您应按注册页面提示提供您本人的真实、有效的信息，例如您个人的手机号码、用户名、头像及/或微信号。如果上述信息（尤其是您的手机号码）发生变化，您应及时通过亿嘉欣平台更新您的信息，以免产生因您的手机号码变更未能及时绑定原账号而导致您无法正常使用原账号的情形。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您设置的用户名称和头像不得出现违反国家法律法规、公序良俗、我们的政策或侵犯他人权益的内容；您不得使用虚假信息、冒用他人身份或未经权利人书面同意使用他人手机号码或其他个人信息注册账号。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（5）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">如果您通过未经官方授权的途径（包括但不限于购买、租借、被赠与、被许可使用，以及通过破坏、篡改、改编亿嘉欣平台用户账号注册系统等方式）获取账号，我们不保证其合法性，我们可能认定相关账号存在不当注册及使用的情形，从而导致该账号被限制使用、冻结、封禁、收回、关闭以及其他可能导致账号无法正常使用的后果，对此，您应自行承担全部风险和责任。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（6）&nbsp;如您为企业，您应前往【App&mdash;我的&mdash;企业账号】完成企业账号注册。通过企业账号使用我们的产品及服务的具体规则应以《企业用户协议》为准。企业账号通常不参与面向普通消费者的优惠活动，具体以企业账号相关规则为准。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.2&nbsp;账号的保管及使用</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;您理解并同意，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">亿嘉欣平台上的用户账号所有权归我们所有，您拥有您所注册的亿嘉欣账号的使用权。一般情况下，我们不会干预您对账号的使用，您需对您注册的亿嘉欣账号项下发生的所有行为及其产生的一切后果负责。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">因此，您应妥善保管您的账号，不向他人泄露您的账号及验证信息。如您因任何原因（包括遭受诈骗）主动泄露您的账号及验证信息或因您存在其他过失行为，导致您的账号遭盗用并对您及他人造成任何损失，您需自行承担由此产生的全部责任。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">如果您发现他人未经您的许可使用您的亿嘉欣账号或您的亿嘉欣账号存在其他安全漏洞或异常情况，您应根据本协议第11条所述联系方式及时与我们取得联系。</u></span>为保护您的账号安全，我们可能会对您的亿嘉欣账号采取暂时性的冻结、封禁或限制使用等措施，并要求您提供您真实有效的身份证明材料，以证明您的身份。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您的账号仅限您本人使用，您不得以任何形式将账号赠与、转让、出借、出租、售卖、分享予他人使用，或将该账号用于任何商业或营利用途等。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（5）&nbsp;您可以使用您所注册的亿嘉欣账号登录亿嘉欣平台各关联版本，这些关联版本可能受不同协议及规则约束。除非另有明确说明，您以亿嘉欣账号登录并使用亿嘉欣平台各关联版本时，将同时受本协议及关联版本相关协议及规则的约束。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（6）&nbsp;为了保障您的账号安全，我们可能会定期或不定期地采用不同的方式对您的账号进行验证，包括但不限于要求输入验证码、短消息验证等。如您无法完成验证或拒绝验证，我们可合理地怀疑您的账号出现异常或被盗，并可能会暂停向您提供服务及/或采取进一步措施。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">1.3&nbsp;账号的注销</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如您选择不再继续使用我们的产品及服务时，您可以注销您的亿嘉欣账号。您可以通过点击&ldquo;我的&mdash;点击头像进入个人资料页&mdash;账号管理&mdash;注销账号&rdquo;进行账号注销，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">注销账号的条件及后果详见【注销页面展示的注销账号规则】。您仅能申请注销您本人的亿嘉欣账号，账号注销后，您将无法使用亿嘉欣账号登录所有亿嘉欣平台，账号内的相关权益（会员等级、积分、优惠券、钱包、礼品卡、账户余额等）会被清除而无法使用，您的个人信息将被删除或进行匿名化处理（但法律法规另有规定的除外）。您理解并同意，注销账号不影响您仍应对账号注销前您账号项下的所有行为承担责任。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.&nbsp;用户行为规范</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您有权根据本协议约定以及我们另行明示授权的范围使用我们的产品及服务，包括但不限于通过亿嘉欣平台或我们所信任的第三方合作方平台浏览、收藏、购买、评价产品并接受产品的配送和交付。您可能可以不时享受我们开展的优惠、促销活动，成为亿嘉欣平台的会员、或与我们的工作人员进行沟通与交流。在此过程中，您应遵守如下规定：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.1&nbsp;当您使用我们的产品及服务时，您应自行配备上网所需的设备，包括手机或其他必备的上网装置等。我们为您准备了iOS、Android等版本的终端应用程序适配您的终端设备，您也可以选择通过我们三方平台中的亿嘉欣小程序享受我们的产品及服务。当您为此接入互联网时，您需自行承担必要的网络通信及流量费用，上述费用将由网络运营商向您直接收取。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2.2&nbsp;在使用我们的产品及服务的过程中，您可能可以通过亿嘉欣平台上传并发布图片、编辑文字、发表评论、设置头像及用户名等，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您理解并同意，您在亿嘉欣平台发布的任何信息不得包含以下内容：</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（1）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">反对宪法所确定的基本原则的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（2）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（3）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">损害国家荣誉和利益的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（4）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（5）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（6）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">煽动民族仇恨、民族歧视，破坏民族团结的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（7）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">破坏国家宗教政策，宣扬邪教和封建迷信的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（8）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">散布谣言，扰乱社会秩序，破坏社会稳定的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（9）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（10）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">侮辱或者诽谤他人，侵害他人合法权益的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（11）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">损害亿嘉欣及其合作方商誉、合法权益的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（12）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">法律、行政法规禁止的其他内容的。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">2.3&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您亦同意不会通过亿嘉欣平台或利用亿嘉欣账号从事以下行为：</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（1）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">冒充任何个人或机构，或以虚假、不实的方式谎称或使人误认为您与任何个人或任何机构有关，包括您以我们的代理人、工作人员或其他类似身份开展任何活动；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（2）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">未经权利人书面许可，收集、处理或传输该权利人的信息和资料，侵犯第三方的专利、商标、著作权、商业秘密或其他专属权利；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（3）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">以任何形式发布、传输广告函件、促销资料、垃圾信息以及任何未经授权发布的内容，造成对他人的骚扰；&nbsp;</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（4）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">企图以任何形式自行或怂恿、鼓励、协助他人（包括为他人提供工具或技术支持）干扰、破坏或限制亿嘉欣平台软件、系统的正常运行，包括但不限于使用未经授权的插件、外挂、程序、病毒、计算机代码等自研或第三方工具对我们的软件、系统、源代码、接入网络的系统和服务器进行非法修改、编译、侵入、反向工程、反向汇编、窃取相关软件和系统中的数据，侵入我们的邮箱或系统向他人传送未经授权的内容；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（5）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">以不正当、不诚信的方式获取或使用我们的产品及服务或滥用权利的，包括但不限于频繁、批量注册/使用账号、利用未经授权的工具或方式参与我们的优惠/促销活动、恶意套取活动权益，开展虚假交易、刷单、提交虚假交易信息，恶意下单、退单、投诉等；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（6）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">开展任何形式的营利性活动，包括但不限于出租、出借、转让、销售、质押其账号及账号内的相关权益（账号内下单金额、数量、频率、范围，持有/转赠卡券数量、频率等超出我们合理认定的正常消费范围的，可能被认定为您利用亿嘉欣账号进行营利性活动）的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（7）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">从事诈骗、洗钱等违法活动的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（8）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">其他影响或可能影响亿嘉欣平台安全运行，违反亿嘉欣政策或违反任何国家法律法规的行为。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">3.&nbsp;广告</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您成为亿嘉欣平台注册用户后，我们或我们信赖的第三方广告服务商可能会通过您提供的手机号码或微信号向您发送广告、推送与我们的产品或服务相关的信息。如您不希望接收这些信息，您可以通过您收到的信息中所载明的方式进行退订，也可以依据本协议第11条所述方式直接与我们联系进行退订。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4.&nbsp;用户个人信息保护</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">您在注册成为我们的用户、使用我们的产品及服务的过程中需要向我们提供您的个人信息。我们深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠。我们制定了《<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">亿嘉欣隐私权政策》</u></span>（查看路径：App/小程序&mdash;我的&mdash;点击头像进入个人资料页&mdash;协议与说明&mdash;隐私权政策）向您说明我们如何处理您的个人信息。您应仔细阅读《亿嘉欣隐私权政策》中的所有内容。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">5.&nbsp;交易规则</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.1&nbsp;产品信息</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1） 您在亿嘉欣平台浏览产品信息时，应当仔细阅读产品信息中包含的全部内容，包括但不限于产品的名称、课程、服务时间等内容（不同产品展示信息存在一定差异，产品信息展示以页面实际情况为准）。您应在认真阅读、理解并完全接受产品信息中包含的全部内容后下单购买。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">亿嘉欣平台上所发布的一切产品信息，包括但不限于图片、视频、文字说明，仅供用户决定是否订购相关产品时参考使用。我们可视情况不时对产品信息进行调整，尽力提高产品信息的准确性、及时性、完整性和有效性。对于此类调整，我们可能不作特别通知。当您通过亿嘉欣平台成功提交订单并完成支付后，产品及订单信息以您提交订单时的信息为准。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">因系统或互联网技术性问题或我们的操作失误导致产品信息发生错误的，我们有权视情况取消订单或作其它处理，无需承担除退回您已付价款外的其他责任。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;我们不对产品能够满足您的特定需求做任何保证。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.2&nbsp;产品订购</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1） 您下单时须填写并确认您希望购买的产品数量、价款、支付方式、服务方式等信息。您下单并成功支付货款，即视为您已知晓、同意并接受产品订单中的全部内容。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;我们收到您的订单后会及时审核订单信息，经审核无误，我们将安排产品制作和交付。如果我们认为您的订单存在异常或我们无法按订单内容向您交付产品的情形，我们会联系您协商订单变更事宜，如您接受变更则我们将按变更后的订单内容向您交付产品，如您不接受变更则您有权取消订单，我们会将相应交易价款原路返还至您的付款账户。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;如果我们推出预售产品，您可以根据页面提示的相关预售规则下单。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您理解并同意，若发生以下任一情形，我们有权单方面取消订单，并将相应价款原路返还至支付账号：</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">我们有合理理由认定您存在违反国家法律法规或本协议约定的行为；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">订单为非正常流程产生，包括但不限于您以利用软件、技术手段、恶意拆单或下单等方式违规参与限购活动、破坏或干预订单生成程序等；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">订单生成后，您的账号权属存在争议或纠纷，当事人无法就争议达成一致；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">4)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">订单生成后，订购人或付款人对交易提出异议；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您违反限购规定，或在一段时间内通过多个用户的亿嘉欣账号下单，我们通过订单的支付账号、收货地址、下单IP地址、联系电话等信息合理判断实际购买人为一人，且多笔订单合计购买数量违反限购规定的；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">由于难以控制的因素或因系统、网络技术发生错误导致订单信息标示错误、相关商品短缺或其他我们难以克服的情况无法根据订单内容交付产品或服务；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">7)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">根据监管部门的要求、指示、通知取消订单；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">8)&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">其它我们认为您通过不正当的手段购买商品或参与我们的优惠、促销活动的情形。</u></span>&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.3&nbsp;产品价格及促销活动</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;您同意并理解，我们有权决定我们的产品及服务的价格，并可以根据市场变化或市场竞争的需要不时变更产品价格。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">为方便您选购产品，我们会在亿嘉欣平台相应位置公布产品价格（包含可能的税金）及价格说明。您实际支付的价格可能受您参与的优惠或促销活动、额外选配费用、运费等因素影响。您应在支付前，通过产品结算页面了解您实际应支付的价款。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;我们可能不时开展优惠/促销活动，优惠/促销活动是指在一定时期内，亿嘉欣针对某些产品做让利处理或针对消费者开展的优惠激励措施。优惠/促销形式包括但不限于：针对特定产品或具体服务的满减、满赠，针对特定用户或付费会员的降价、优惠、打折活动等。具体优惠/促销活动详情应以届时页面公布的通知、公告或规则为准。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;如果我们有合理理由怀疑您通过不正当或不合法的手段参加优惠/促销活动的，我们有权取消您参与优惠/促销活动的资格、取消您的订单或终止发货。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;电商产品的配送</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">1）&nbsp;如您在我们的&ldquo;电商&rdquo;板块购买产品，通常情况下我们会委托第三方配送合作商向您配送产品。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">配送合作商在向您提供配送服务过程中对您的合法权益造成损害的，该责任由配送合作商承担。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">2）&nbsp;请务必确认您所填写的收件人信息（包括收货地址、联系电话）的准确性及通讯方式的畅通，以便我们的配送合作商能够准确、及时地向您配送产品。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">3）&nbsp;收到商品后，请注意及时查验商品并确认收货。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">如您未及时点击确认收货，系统通常将在发货后第10天将订单状态变更为已完成（具体以订单显示情况为准）。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">4）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您可在法律规定的条件及时间范围内申请退换货及/或退款。您的订单完成后，超出法律规定的条件及时间范围，我们将不再为您处理退换货及/或退款事宜。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;如果您填写的收货人不是您本人，收货人的行为亦会被视为您本人的行为，您需对收货人的行为承担连带责任。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;订单生成后，订单中的收货地址、联系电话、收货人等信息无法变更，如果因为您填写的收货人姓名、收货人电话、收货地址等信息错误或您提供的联系电话无法接通，导致我们或第三方配送合作商无法及时向您完成配送服务的，该等风险及损失将由您自行承担。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（5）&nbsp;我们在产品页面上显示的取餐时间/配送时效是根据库存情况、一般情况下的操作时间、第三方配送合作商提供的时间等因素所预估，仅供您参考。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（6）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您或您填写的收货人在收货时，应当在配送人员在场（门店自取产品的情形下，应在门店工作人员在场）的情况下对产品进行当面验收。如您或相关收货人在验收时发现任何问题，应当经配送人员或在配送人员在场时与我们联系并协商解决。如您授权他人代为签收的，应当一并告知被授权人该项验收要求。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">5.5&nbsp;预付卡管理</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">为满足用户的不同需求，我们提供不同形式的礼品卡等单用途商业预付卡。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">购卡前请您务必仔细查阅《亿嘉欣单用途商业预付卡章程》</u></span>（查看路径：App/小程序&mdash;我的&mdash;点击头像进入个人资料页&mdash;协议与说明&mdash;亿嘉欣单用途商业预付卡章程）<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">及购买页面显示的相关政策和规则。</u></span>建议您从官方正规渠道购买卡券。谨防低价、折扣、返利、刷单等为诱饵的任何形式的诈骗。卡券不可用于营利性商业用途。转赠时（如适用），请谨慎核实、慎重操作，避免上当受骗。请您自行保管妥当账号信息，我们不会通过任何方式索要您的账号信息。如遇诈骗，请您立即报警处理。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">6.&nbsp;责任限制</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.1&nbsp;为了完善产品功能、改善用户体验、保障产品与/或服务的安全性，我们可能会定期或不定期对我们的软件进行更新。为不影响您正常使用，您应将亿嘉欣平台适用软件及系统更新到最新版本。<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">部分功能及活动，需升级最新版本方可实现，建议您及时更新。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.2&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">在符合国家法律法规规定的情况下，我们不对亿嘉欣平台系统做出明示或暗示的承诺或保证，亦不对如下事项做出担保（但我们会尽合理努力对相关问题进行修复）：</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">亿嘉欣平台与其他软硬件、系统完全兼容；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">不受不可抗力、黑客攻击、网络中断、通信线路故障、电力中断、第三方服务商的行为、政府行为或政策调整、我们对亿嘉欣平台的规划/调整/改版、用户操作不当等原因的干扰、破坏或其他影响；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">不会发生服务中断、数据丢失、账号丢失、账号内数据损毁等损失或风险；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（4）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">经非官方途径下载亿嘉欣平台或使用我们的产品及服务不会出现涉及安全性或稳定性的问题，包括感染计算机病毒、遭受未经授权工具的入侵等；</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（5）&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您在使用我们的产品及服务的过程中遇见其他我们无法控制或合理遇见的情形。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.3&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">在使用我们的产品及服务的过程中，可能会遇到我们因不可抗力导致我们无法全部或部分履行本协议的情形。在此情形下，我们会尽合理努力进行修复，对由此给您造成的损失，我们会在国家法律法规允许的范围内免责。&ldquo;不可抗力&rdquo;是指不能预见、不能避免且不能克服的客观情况，包括但不限于发生自然灾害，如洪水、地震、火灾、台风、海啸等，政府行为，如征收、征用、战争、因疫情或其他事件导致政府采取的特定行为或措施、政策变化、法律法规的变动，社会事件，如罢工、骚乱等。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.4&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您理解并同意，为了整体运营的需要，我们有权自行决定对业务进行变更、调整，对亿嘉欣平台进行检修、维护、升级及其他技术上的调整。如我们合理认为相关调整可能会影响您的合法权益，我们会尽最大努力通知您。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">6.5&nbsp;<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">在任何情况下，我们均不承担任何间接、附带、特别、衍生的赔偿，比如基于您因使用我们的产品或服务可能获得的收益、利润、商业机会等可得利益计算所得的损失；也不会承担任何非因我们的原因而产生的损害赔偿。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">7.&nbsp;违约责任</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">7.1&nbsp;如果我们发现或者收到他人举报投诉或您违反本协议约定或存在任何恶意、违法行为嫌疑的，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">我们有权不经通知随时对相关内容进行删除、屏蔽，并有权视情况采取包括警告、要求限期改正、限制账号部分或全部功能、中止或终止提供服务、冻结、封禁或永久关闭账号及法律法规规定的其他处置措施</u></span>。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">7.2&nbsp;您理解并同意，我们可能会记录您违反适用法律法规、本协议约定、侵犯我们或其他方合法权益的行为并留存相应的数据，并将其作为证据提交有关部门，并配合有关部门的调查。因您的行为造成您的亿嘉欣账号无法正常使用，及/或给我们及相关方造成任何损失的，您应自行承担全部责任。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">7.3&nbsp;因您违反本协议约定，引起第三方投诉或诉讼等索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您的违法或违约等行为导致我们向任何第三方赔偿、遭受国家机关处罚或承担其他损失的（<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费、保全费等间接经济损失</u></span>），您还应足额赔偿我们因此遭受的全部损失。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">8.&nbsp;协议的终止</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">8.1&nbsp;您有权通过以下任一方式终止本协议：</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（1）&nbsp;您可以通过亿嘉欣平台自助服务或联系我们的客服为您注销账号，请您仔细阅读注销账号页面的说明，以了解账号注销条件及注销效果。本协议于账号注销之日终止。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（2）&nbsp;本协议发生变更（包括但不限于修改、替换或升级等）后，在变更内容生效前，您可以选择停止使用我们的产品及服务以示不愿意接受变更内容。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">（3）&nbsp;您明示不愿继续使用亿嘉欣平台服务，且符合亿嘉欣平台终止条件的。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">8.2&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">发生下列情形之一的，亿嘉欣有权立即终止协议且不承担任何责任：</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（1）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您违反本协议约定，我们依据违约条款终止本协议的。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（2）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">您不是以生活消费为目的的消费者，或实施恶意注册账号、虚假交易、恶意退款、恶意差评、编造传播虚假信息、发布违禁信息、采取不正当手段谋利等行为，我们对您的账号予以关闭的。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（3）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">我们收到监管机关或其授权机构的通知、要求、命令和指示要求终止向您提供产品或服务的。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">（4）&nbsp;<u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">其它应当终止服务的情况。</u></span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">8.3&nbsp;本协议终止后，&nbsp;我们将依照法律规定保留亿嘉欣平台应当留存的相关信息。如果您在本协议终止之前存在违反本协议的行为，我们有权继续追究您的法律责任。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">9.&nbsp;知识产权</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">9.1&nbsp;与我们的产品及服务相关的所有信息及内容，包括但不限于：亿嘉欣平台上的文字、软件、图片、视频、音频、图表等，以及通过亿嘉欣平台或我们合作的第三方平台展示的亿嘉欣商标、标识或标记，其知识产权（包括著作权、商标权、专利权、商业秘密等）及其他相关权益均归我们享有，或我们已合法取得相关权利人的授权。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">9.2&nbsp;未经我们或相关权利人的书面授权，您不得以任何形式擅自使用、修改、复制、改编、公开传播、发行、翻译其知识产权归属于我们或相关权利人的任何信息及内容，亦不得将该等信息或内容以单独或结合其他信息或内容的方式予以展示、使用或申请注册商标、进行域名注册或版权登记等，也不得向他人明示或暗示您有权展示、使用或以任何形式处理这些信息或内容。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">9.3&nbsp;您提供、上传、创作、发布在亿嘉欣平台的文字、评论、图片、视频、音频等任何形式的信息或内容，您同意免费授予我们至保护期终止为止、不可撤销的、全球范围的、非独家、可供我们再许可的许可，使用相关信息或内容的著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权等权利。您同意我们有权存储、使用、复制、修改、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播以上信息内容，使用范围包括但不限于亿嘉欣平台以及我们与第三方的合作页面。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">10.&nbsp;管辖</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">本协议的订立、执行、解释及争议的解决均应适用中华人民共和国法律。如您与亿嘉欣就本协议内容或其履行发生任何争议，首先应尽量友好协商；协商不成时，<span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;"><u style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">任一方均可向被告所在地有管辖权的人民法院提起诉讼</u></span>。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">11.&nbsp;联系方式</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">如您在阅读本协议时或在使用我们产品及服务的过程中有任何疑问、意见或建议，您可以通过以下方式与我们取得联系：（1）客户端应用程序和小程序端点击&ldquo;我的&rdquo;-&ldquo;反馈意见&rdquo;；（2）拨打客服热线：【<span style="font-family: 'Helvetica Neue'; font-size: 13px;">13154949714</span><span style="text-indent: 1.5em;">】。我们会在收到您的意见及建议后尽快为您答复。</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">&nbsp;</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;"><span style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); font-weight: bolder;">12.&nbsp;&nbsp;其他</span></p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">12.1&nbsp;如本协议中的任何条款与国家法律法规相抵触时，则这些条款将在不违反适用法律的前提下按照接近相关条款原文目的之原则进行重新解释，而本协议其它条款仍具有其完整的法律效力并保持对双方的法律约束力。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">12.2&nbsp;本协议同样适用于所有版本的亿嘉欣平台，以及其任何更新、补充或替代的产品，除非该等更新、补充或替代产品附有单独适用之条款，在此情况下，该等单独条款应优先适用。</p>
<p style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); margin: 0px; padding: 0px; text-indent: 1.5em; font-family: 'Microsoft YaHei', Arial, 'Lucida Grande', Verdana; font-size: 13.248px; white-space-collapse: preserve; background-color: #f5f5f9;">12.3 本协议的所用标题和序号均为方便阅读而设定，不能作为解释本协议的依据，亦不影响任何条款的实际含义及其效力。</p>`, // 用于存储后端返回的富文本内容
    };
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 100%;
  text-align: left;
  overflow-wrap: break-word; /* 防止内容超出 */
}
</style>